import axios from 'axios';

const api = {
    get: async (route: string) => {
        return axios.get(`${process.env.REACT_APP_API_URL}/${route}`, {
            headers: {
                'X-API-Key': process.env.REACT_APP_API_KEY,
            },
        });
    }
};

export default api;